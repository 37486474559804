<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">

        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">KÁF/KSZF BIZTOSÍTÁS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <ul>
                <li><a class="scroll" (click)="scrollToElement('A1')">KÁF - Közúti árufuvarozók felelősségbiztosítása</a></li>
                <li><a class="scroll" (click)="scrollToElement('A2')">KSZF - Közúti személyszállítók felelősségbiztosítása</a></li>
              </ul>
              <h2 class="subtitle">
                <b>Vonatkozó jogszabályok</b>
              </h2>

              <ul class="ulstyle">
                <li>2014. évi LXXXVIII. törvény a biztosítási tevékenységről</li>
                <li>2013. évi V. törvény a Polgári Törvénykönyvről</li>
                <li>261/2011. (XII.7.) Korm. rendelet a díj ellenében végzett közúti árutovábbítási, a saját számlás áruszállítási, valamint az autóbusszal díj ellenében végzett személyszállítási és a saját számlás személyszállítási tevékenységrol, továbbá az ezekkel összefüggo jogszabályok módosításáról</li>
              </ul>

              <h2 class="subtitle"><strong>A biztosítási módozathoz tartozó számlaszám</strong></h2>
              <table style="width: 100%;"><tr><td><span>10918001-00000068-64440068</span></td><td>IBAN: <span>HU41 1091 8001 0000 0068 6444 0068</span></td></tr></table>
              <div id="A1">
                <hr class="fancy-line" />
                <p>
                  &nbsp;
                </p>
                <h1>
                  KÁF - Közúti árufuvarozók felelősségbiztosítása
                </h1>
                <p>&nbsp;</p>
                <p class="question">
                  <b>Mi a biztosítás tárgya?</b>
                </p>
                <p>
                  A biztosítási szerződés alapján társaságunk - fedezet igazolás kiállításával – igazolja
                  a közúti árufuvarozást végzők részére előírt, megfelelő pénzügyi helyzet meglétének
                  biztosítására a vagyoni biztosítékot kiváltó Közúti Árufuvarozók Felelősségbiztosítási
                  szerződés létrejöttét. &nbsp;&nbsp;
                </p>
                <p class="question">
                  <b>Mire terjed ki a biztosítás fedezete?</b>
                </p>
                <p>
                  A biztosítási védelem kiterjed a Magyarországon okozott és bekövetkezett, valamint
                  nemzetközi árufuvarozói tevékenység esetén a külföldi országokban okozott és bekövetkezett
                  biztosítási eseménnyel összefüggő fizetési eseményekre is.
                </p>
                <p class="question">
                  <b>Mi a díjképzés alapja, és mekkora fedezetet nyújt?</b>
                </p>
                <p>
                  A biztosítási díj gépjárművenként kerül meghatározásra.
                </p>
                <p>
                  A Biztosító kockázatviselésének felső határa, mint biztosítási összeg, a biztosított
                  gépjárműveire számítva az alábbiak szerint kerül meghatározásra. Egy gépjármű (járműszerelvény)
                  esetén, illetőleg az első gépjárműre (járműszerelvényre) számítva 9.000 EUR, és
                  minden további gépjárműre (járműszerelvényre) nézve egyenként 5.000 EUR.
                </p>
                <p class="question">
                  <b>Milyen önrész vonatkozik a KÁF károkra?</b>
                </p>
                <p>
                  A biztosítási összeg terhére történő kifizetés összegéből a Biztosító nem von le
                  önrészesedést.
                </p>
                <h2 class="subtitle">Letölthető dokumentumok</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}graint_kaf.pdf">Biztosítási termékismertető</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20240215.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2024.02.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a><br /></li>
                </ul>
                <h2 class="subtitle">Kárbejelentési dokumentumok</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}baf_kaf_karbejelento_20160101.pdf">BÁF/KÁF kárbejelentő lap</a></li>
                </ul>
                <h2 class="subtitle">Archívum</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20230615.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20220401.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2022.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20211220.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20210501.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2021.05.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20200701.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2020.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20190426.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2019.04.26-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20190101.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2019.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20180808.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20180701.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20180525.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20180223.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20180101.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20170601.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20170401.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20170101.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20160901.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2016.09.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20160701.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_feltetelcsomag_20160101.pdf">KÁF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2016.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_ugyfeltajekoztato_es_feltetelek_20120921.pdf">KÁF Ügyféltájékoztató és Biztosítási Szerződési Feltételek 2012.09.21-től hatályos</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20131001.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2013.10.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130701.pdf">Ügyféltájékoztató 2013.07.01-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130301.pdf">Ügyféltájékoztató 2013.03.01-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_ugyfeltajekoztato_es_feltetelek.pdf">KÁF Ügyféltájékoztató és Biztosítási Szerződési Feltételek 2012.január 1-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20120101.pdf">Ügyféltájékoztató 2012. január 1-jétől</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20110701.pdf">Ügyféltájékoztató 2011. július 1-jétől</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_ugyfeltajekoztato.pdf">KÁF Ügyféltájékoztató (hatályon kívül)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_allomanyvaltozas.pdf">KÁF Állományváltozás  bejelentő (hatályon kívül)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_vagyonbizt.pdf">KÁF Általános vagyonbiztosítási feltételek (hatályon kívül)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kaf_kulonos_felt.pdf">KÁF Különös feltételek (hatályon kívül)</a></li>

                </ul>
              </div>
              <div id="A2">
                <hr class="fancy-line" />
                <p>
                  &nbsp;
                </p>
                <h1 class="subtitle">

                  KSZF - Közúti személyszállítók felelősségbiztosítása
                </h1>
                <p>&nbsp;</p>
                <p class="question">
                  <b>Mi biztosítás tárgya?</b>
                </p>
                <p>
                  A biztosítási szerződés alapján társaságunk - fedezet igazolás kiállításával – igazolja
                  a közúti személyszállítást végzők részére előírt, megfelelő pénzügyi helyzet meglétének
                  biztosítására a vagyoni biztosítékot kiváltó Közúti Személyszállítók Felelősségbiztosítási
                  szerződés létrejöttét.
                </p>
                <p class="question">
                  <b>Mire terjed ki a biztosítás fedezete?</b>
                </p>
                <p>
                  A biztosítási védelem kiterjed a Magyarországon okozott és bekövetkezett, valamint
                  nemzetközi autóbuszos személyszállítói tevékenység esetén a külföldi országokban
                  okozott és bekövetkezett biztosítási eseménnyel összefüggő fizetési eseményekre
                  is.
                </p>
                <p class="question">
                  <b>Mi a díjképzés alapja, és mekkora fedezetet nyújt?</b>
                </p>
                <p>
                  A biztosítási díj gépjárművenként kerül meghatározásra.
                </p>
                <p>
                  A Biztosító kockázatviselésének felső határa, mint biztosítási összeg, egy autóbusz
                  esetén, illetőleg több autóbusz esetén az első gépjárműre 9.000 EUR, és minden további
                  gépjárműre nézve egyenként 5.000 EUR.
                </p>
                <p class="question">
                  <b>Milyen önrész vonatkozik a KSZF károkra?</b>
                </p>
                <p>
                  A biztosítási összeg terhére történő kifizetés összegéből a Biztosító nem von le
                  önrészesedést.
                </p>
                <p class="question">
                  <b>További információra van szüksége?</b>
                </p>
                <p>
                  <a [routerLink]="['/gyik']">Gyakran ismételt kérdések menü alatt</a> részletes tájékoztatást
                  nyújtunk Önnek a KÁF/KSZF biztosítás szolgáltatásáról, a szerződés megkötésére és
                  megszűntetésére vonatkozó tudnivalókról, a kockázatban állásról és a kárügyintézésről.
                </p>
                <p>
                  Kérdés esetén készséggel állunk rendelkezésére hívja bizalommal ügyfélszolgálati
                  munkatársainkat. Adminisztratív ügyintézés és Kárrendezési hely: 1211 Budapest,
                  Szállító u. 4. Tel.: 06 1 6666 200
                </p>
                <h2 class="subtitle">Letölthető dokumentumok:</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_kszf.pdf">Biztosítási termékismertető</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20240215.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a><br /></li>
                </ul>
                <h2 class="subtitle">Archívum</h2>
                <ul>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20230615.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2023.06.15-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20211220.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2021.12.20-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20210501.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2021.05.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20200701.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2020.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20190426.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2019.04.26-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20190101.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2019.01.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a><br /></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20180808.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20180701.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20180525.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20180223.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20180101.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20170601.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2017.06.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20170401.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2017.04.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20170101.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2017.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20160701.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2016.07.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_feltetelcsomag_20160101.pdf">KSZF biztosítási feltételek, Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Biztosítási Szerződési Feltételek (2016.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_ugyfeltajekoztato_es_feltetelek_20120921.pdf">KSZF Ügyféltájékoztató és Biztosítási Szerződési Feltételek 2012.09.21-től hatályos</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20131001.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2013.10.01-től hatályos)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130701.pdf">Ügyféltájékoztató 2013.07.01-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130301.pdf">Ügyféltájékoztató 2013.03.01-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_ugyfeltajekoztato_es_feltetelek.pdf">KSZF Ügyféltájékoztató és Biztosítási Szerződési Feltételek 2012.január 1-től</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20120101.pdf">Ügyféltájékoztató 2012. január 1-jétől</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20110701.pdf">Ügyféltájékoztató 2011. július 1-jétől</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_ugyfeltajekoztato.pdf">KSZF Ügyféltájékoztató (hatályon kívül)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_allomanyvaltozas.pdf">KSZF Állományváltozás bejelentő (hatályon kívül)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_vagyonbizt_felt.pdf">KSZF Általános vagyonbiztosítási feltételek (hatályon kívül)</a></li>
                  <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kszf_kulonos_felt.pdf">KSZF Különös feltételek (hatályon kívül)</a></li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
