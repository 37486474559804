<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
              <div class="row justify-content-center pt-4">
                <div class="col-lg-12">
                  <h1 class="bold">VEZETŐSÉG</h1>
                </div>
              </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <table class="table table-striped table-borderless">
                  <tbody>
                    <tr>
                      <td class="col-lg-10">Vezérigazgató:</td>
                      <td class="col-lg-2">
                        dr. Nyilasy Bence
                      </td>
                    </tr>
                    <tr>
                      <td>Gazdasági igazgató és számviteli rendért felelős vezető:</td>
                      <td>
                        Hevő Krisztián
                      </td>
                    </tr>
                    <tr>
                      <td>Vezető aktuárius:</td>
                      <td>
                        Retteghy Orsolya
                      </td>
                    </tr>
                    <tr>
                      <td>Vezető jogász:</td>
                      <td>
                        dr. Szabó Mariann
                      </td>
                    </tr>
                    <tr>
                      <td>Vezető kockázatkezelő:</td>
                      <td>
                        Tóth András
                      </td>
                    </tr>
                    <tr>
                      <td>Megfelelőségi vezető:</td>
                      <td>
                        dr. Csermely Tibor
                      </td>
                    </tr>
                    <tr>
                      <td>Belső ellenőr:</td>
                      <td>
                        Gavaltik János
                      </td>
                    </tr>
                    <tr>
                      <td>Alkusz értékesítési igazgató:</td>
                      <td>
                        Dér Ervin
                      </td>
                    </tr>
                    <tr>
                      <td>Állománykezelési adminisztrációs igazgató:</td>
                      <td>
                        Birkás Balázs
                      </td>
                    </tr>
                    <tr>
                      <td>Informatikai igazgató:</td>
                      <td>
                        Babusa Tamás
                      </td>
                    </tr>
                    <tr>
                      <td>Kárrendezési igazgató:</td>
                      <td>
                        Sándor Pál
                      </td>
                    </tr>
                    <tr>
                      <td>Üzletfejlesztési vezető:</td>
                      <td>
                        Buró Attila
                      </td>
                    </tr>
                    <tr>
                      <td>ESG felelős, projekt manager:</td>
                      <td>
                        Zöldi Tamás
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-lg-12 pt-3">
                <div class="h4 text-primary">
                  Igazgatóság
                </div>
              </div>
              <div class="col-lg-12">
                <table class="table table-striped table-borderless">
                  <tbody>
                    <tr>
                      <td class="col-lg-10">Elnök:</td>
                      <td class="col-lg-2">
                        Erdélyi Barna
                      </td>
                    </tr>
                    <tr>
                      <td>Tagok:</td>
                      <td>
                        dr. Nyilasy Bence
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        Tóth Szabolcs Gábor
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        Havas István
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-lg-12 pt-3">
                <div class="h4 text-primary">
                  Felügyelőbizottság
                </div>
              </div>
              <div class="col-lg-12">
                <table class="table table-striped table-borderless">
                  <tbody>
                    <tr>
                      <td class="col-lg-10">Elnök:</td>
                      <td class="col-lg-2">
                        dr. Szász Károly
                      </td>
                    </tr>
                    <tr>
                      <td>Tagok:</td>
                      <td>
                        dr. Tóth Tímea
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        Nagy-Simon Judit
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-lg-12 pt-3">
                <div class="h4 text-primary">
                  Audit bizottság
                </div>
              </div>
              <div class="col-lg-12">
                <table class="table table-striped table-borderless">
                  <tbody>
                    <tr>
                      <td class="col-lg-10">Elnök:</td>
                      <td class="col-lg-2">
                        Havas István
                      </td>
                    </tr>
                    <tr>
                      <td>Tagok:</td>
                      <td>
                        Tóth Szabolcs Gábor
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        Nagy-Simon Judit
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
