import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonLibraryModule } from '@granit/common-library';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NyitolapComponent } from './components/nyitolap/nyitolap.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
/*Termékek*/
import { KgfbComponent } from './components/termekek/kgfb/kgfb.component';
import { CascoComponent } from './components/termekek/casco/casco.component';
import { EgeszsegComponent } from './components/termekek/egeszseg/egeszseg.component';
import { KafKszfComponent } from './components/termekek/kaf-kszf/kaf-kszf.component';
import { UtasComponent } from './components/termekek/utas/utas.component';
import { OtthonComponent } from './components/termekek/otthon/otthon.component';
import { CmrComponent } from './components/termekek/cmr/cmr.component';
import { VagyonComponent } from './components/termekek/vagyon/vagyon.component';
import { HazmesterComponent } from './components/termekek/hazmester/hazmester.component';
import { MfoComponent } from './components/termekek/mfo/mfo.component';
import { PanoramaComponent } from './components/termekek/panorama/panorama.component';
import { KosdUjraComponent } from './components/termekek/kosd-ujra/kosd-ujra.component';
import { VodafoneComponent } from './components/termekek/vodafone/vodafone.component';
/*Kárrendezés*/
import { KarrendezesComponent } from './components/karrendezes/karrendezes/karrendezes.component';
import { SzerzodottJavitoPartnerekComponent } from './components/karrendezes/szerzodott-javito-partnerek/szerzodott-javito-partnerek.component';
import { KarrendezoPartnerekComponent } from './components/karrendezes/karrendezo-partnerek/karrendezo-partnerek.component';
import { KarrendezesiTajekoztatokComponent } from './components/karrendezes/karrendezesi-tajekoztatok/karrendezesi-tajekoztatok.component';
/*Ügyfeleinknek*/
import { GyikComponent } from './components/ugyfeleinknek/gyik/gyik.component';
import { PanaszbejelentesComponent } from './components/ugyfeleinknek/panaszbejelentes/panaszbejelentes.component';
import { OnlinePanaszbejelentesComponent } from './components/ugyfeleinknek/panaszbejelentes/online-panaszbejelentes/online-panaszbejelentes.component';
import { FogyasztoiOldalComponent } from './components/ugyfeleinknek/fogyasztoi-oldal/fogyasztoi-oldal.component';
import { PenzugyiNavigatorComponent } from './components/ugyfeleinknek/penzugyi-navigator/penzugyi-navigator.component';
import { KozzetetelekComponent } from './components/ugyfeleinknek/kozzetetelek/kozzetetelek.component';
import { FizetokepessegComponent } from './components/ugyfeleinknek/kozzetetelek/fizetokepesseg/fizetokepesseg.component';
import { Bit108A1Component } from './components/ugyfeleinknek/kozzetetelek/bit108-a1/bit108-a1.component';
import { OnlineFizetesiTajekoztatoComponent } from './components/ugyfeleinknek/online-fizetesi-tajekoztato/online-fizetesi-tajekoztato.component';
import { FizetesiTajekoztatoGyfkComponent } from './components/ugyfeleinknek/fizetesi-tajekoztato-gyfk/fizetesi-tajekoztato-gyfk.component';
import { DokumentumIgenylesComponent } from './components/ugyfeleinknek/dokumentum-igenyles/dokumentum-igenyles.component';
import { ElegedettsegmeresComponent } from './components/ugyfeleinknek/elegedettsegmeres/elegedettsegmeres.component';
/*Rólunk*/
import { VezetosegComponent } from './components/rolunk/vezetoseg/vezetoseg.component';
import { HirekComponent } from './components/rolunk/hirek/hirek.component';
import { EngedelyekComponent } from './components/rolunk/engedelyek/engedelyek.component';
import { KapcsolatComponent } from './components/rolunk/kapcsolat/kapcsolat.component';
import { AllasajanlatokComponent } from './components/rolunk/allasajanlatok/allasajanlatok.component';
import { ImpresszumComponent } from './components/app-footer/impresszum/impresszum.component';
import { JogiNyilatkozatComponent } from './components/app-footer/jogi-nyilatkozat/jogi-nyilatkozat.component';
import { NevvaltasComponent } from './components/rolunk/nevvaltas/nevvaltas.component';

/* Cookie Consent*/
import { SutikComponent } from './components/sutik/sutik.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';



const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  palette: {
    popup: {
      background: '#974064'
    },
    button: {
      background: '#49ffa7'
    }
  },
  theme: 'classic',
  type: 'info',
  content: {
    message: 'Tájékoztatjuk, hogy a Gránit Biztosító Zrt. által üzemeltetett www.granitbiztosito.hu weboldalra való belépéssel - ha ezt az Ön (látogató) által használt böngésző beállítások engedik, vagy azt Ön az oldal első látogatásakor kifejezetten jóváhagyja - a weboldal automatikusan elmenthet információkat az Ön számítógépéről, illetve a böngészésre használt eszközéről (tablet, okostelefon), illetve azon ilyen célból ún. számítógépes „sütiket” (cookie) helyezhet el.',
    dismiss: 'Megértettem!',
    link: 'További információ',
    href: '/sutik'
  }
};

@NgModule({
  declarations: [
    AppComponent,    
    NyitolapComponent,
    AllasajanlatokComponent,
    KgfbComponent,
    AppHeaderComponent,
    AppFooterComponent,
    GyikComponent,
    CascoComponent,
    EgeszsegComponent,
    KafKszfComponent,
    UtasComponent,
    OtthonComponent,
    CmrComponent,
    VagyonComponent,
    HazmesterComponent,
    MfoComponent,
    PanoramaComponent,
    KarrendezesComponent,
    SzerzodottJavitoPartnerekComponent,
    VezetosegComponent,
    HirekComponent,
    EngedelyekComponent,
    KapcsolatComponent,
    PanaszbejelentesComponent,
    FogyasztoiOldalComponent,
    PenzugyiNavigatorComponent,
    KozzetetelekComponent,
    OnlineFizetesiTajekoztatoComponent,
    FizetesiTajekoztatoGyfkComponent,
    DokumentumIgenylesComponent,
    FizetokepessegComponent,
    Bit108A1Component,
    KarrendezoPartnerekComponent,
    KarrendezesiTajekoztatokComponent,
    OnlinePanaszbejelentesComponent,
    ImpresszumComponent,
    JogiNyilatkozatComponent,
    NevvaltasComponent,
    KosdUjraComponent,
    VodafoneComponent,
    SutikComponent,
    ElegedettsegmeresComponent
  ],
  imports: [
    RouterModule,    
    AppRoutingModule,
    CommonLibraryModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDhUknjISERQPuVeWHyYE2nwqyGu8M8FdU'
    }),
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  entryComponents: [

  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
